import React, { createContext, ReactNode, useMemo, useEffect, useState, Fragment } from "react";
import OpenAPIClientAxios from "openapi-client-axios";

// @ts-ignore
export const OpenAPIContext: React.Context<{
  api: OpenAPIClientAxios;
}> = createContext({ api: undefined });

type OpenAPIClientAxiosOpts = ConstructorParameters<
  typeof OpenAPIClientAxios
>[0];

interface Props extends OpenAPIClientAxiosOpts {
  children?: ReactNode;
}

export const OpenAPIProvider = ({ children, ...clientOpts }: Props) => {
  const [loaded, setLoaded] = useState(true);

  const api = useMemo(() => new OpenAPIClientAxios({ ...clientOpts }), []);
  useEffect(() => {
    if (!loaded) {
      try {
        api.initSync();
        setLoaded(true);
      } catch (err) { }
    }
  }, [loaded]);

  if (!loaded) {
    return <Fragment />
  }

  return (
    <OpenAPIContext.Provider value={{ api }}>
      {children}
    </OpenAPIContext.Provider>
  );
};
