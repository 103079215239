import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

interface Props {
    LoginPageComponent?: React.ReactElement
    RegisterPageComponent?: React.ReactElement
    PasswordRemindPageComponent?: React.ReactElement
}

export default function AppLoginRoutes(
    props: Props
) {
    return (
        <Fragment>
            {props.LoginPageComponent && (
                <Route path={'/login'}>{props.LoginPageComponent}</Route>
            )}
            {props.RegisterPageComponent && (
                <Route path={'/register'}>{props.LoginPageComponent}</Route>
            )}
            {props.PasswordRemindPageComponent && (
                <Route path={'/password-remind'}>
                    {props.PasswordRemindPageComponent}
                </Route>
            )}
        </Fragment>
    )
}
