import React, { Fragment } from 'react'
import { AppRouteRoute } from './AppRoutes';
import { Route } from 'react-router-dom'
import UserSpaceWrapper from './UserSpaceWrapper'

interface Props {
    children?: any,
    UserSpaceRoutes?: AppRouteRoute[]
    Routes?: AppRouteRoute[],
    UserSpaceWrapper?: any
}

export default function AppUserRoutes(props: Props) {

    const UserSpaceRoutes = <Fragment>
        {!!props.UserSpaceRoutes && (
            <Fragment>
                {props.UserSpaceRoutes.map((r: AppRouteRoute, index: number) => {
                    return (
                        <Route key={'user-space-route-' + index} path={r.path} exact={r.exact}>
                            {r.comp}
                        </Route>
                    )
                })}
            </Fragment>
        )}
    </Fragment>

    return (
        <Fragment>
            {!!props.Routes && (
                <Fragment>
                    {props.Routes.map((r: AppRouteRoute, index: number) => {
                        return <Route key={'user-route-' + index} path={r.path} exact={r.exact}>{r.comp}</Route>
                    })}
                </Fragment>
            )}

            <UserSpaceWrapper>
                {props.UserSpaceWrapper ?
                    React.cloneElement(props.UserSpaceWrapper, {
                        children: UserSpaceRoutes
                    }) :
                    UserSpaceRoutes
                }
            </UserSpaceWrapper>
        </Fragment>
    )
}
