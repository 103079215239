import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useURequest } from './OpenApi/useURequest';

export interface AppRouteRoute {
  path: string
  comp: any,
  exact?: boolean
}

export interface AppRoutesProps {
  children?: any,
}

export default function AppRoutes(props: AppRoutesProps) {
  const [loadData, loadDataParams] = useURequest('NAEPing');

  useEffect(loadData, []);

  if (loadDataParams.loading || loadDataParams.data.data.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <Switch>
        {props.children}
      </Switch>
    </Router>
  )
}
