import { useContext, useState, useCallback } from 'react'
import { OpenAPIContext } from './OpenAPIProvider'
import { AxiosResponse, AxiosError } from 'openapi-client-axios'

interface DataResponse<Type> {
  data: Type[]
  records: number
  totals: any
}

export function useUList<Type>(
  schema: string,
  fieldsToReturn: string[]
): [
  any,
  {
    loading: boolean
    error?: Error
    data: DataResponse<Type>
  }
] {
  const { api } = useContext(OpenAPIContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [data, setData] = useState<DataResponse<Type>>({
    data: [],
    records: 0,
    totals: {}
  })

  const operationMethod: any = useCallback(
    async (
      filters: any = [],
      page: number = 1,
      pageSize: number = 50,
      sort: [],
      customFieldsToReturn?: [],
      extraData?: any,
      totals?: any
    ) => {
      const requestParams = [
        schema,
        {
          fieldsToReturn: customFieldsToReturn
            ? customFieldsToReturn
            : fieldsToReturn,
          filters,
          page,
          pageSize,
          sort,
          extraData,
          totals
        }
      ]

      setLoading(true)
      const client = await api.getClient()
      let res: AxiosResponse
      try {
        res = await client['NAEUList'](...requestParams)
        const resData: DataResponse<Type> = res.data
        setData(resData)
        setError(undefined)
      } catch (err) {
        // @ts-ignore
        setError(err)
        setData({ data: [], records: 0, totals: {} })
      }
      setLoading(false)
      // @ts-ignore
      return res
    },
    [setLoading, setData, setError]
  )

  return [operationMethod, { loading, error, data }]
}
