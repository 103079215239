import { useEffect } from 'react'
import { OpenApi } from '../index'
import { naeUserState } from '../state/NaeUserState'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'

export function useULogin(): [
  any,
  (data: any) => void,
  (username: string, password: string) => Promise<any>
] {
  const [userState, setUserState] = useRecoilState(naeUserState)
  const [doAuthLogin, doAuthLoginParams] =
    OpenApi.useOperationMethod('NAEauthDoLogin')

  const doLogin = (username: string, password: string) => {
    return doAuthLogin(undefined, {
      username,
      password
    })
  }

  useEffect(() => {
    if (doAuthLoginParams.error) {
      toast.error('Prisijungimo klaida')
    } else if (doAuthLoginParams.data) {
      if (doAuthLoginParams.data.user) {
        setUserState(doAuthLoginParams.data.user);
      }
      if (doAuthLoginParams.data.token) {
        window.localStorage.setItem('token', doAuthLoginParams.data.token);
        location.href = '/';
      }
    }
  }, [doAuthLoginParams])

  return [userState, setUserState, doLogin]
}