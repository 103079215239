import { useContext, useState, useCallback } from "react";
import { OpenAPIContext } from "./OpenAPIProvider";
import {
  AxiosResponse,
  AxiosError,
} from "openapi-client-axios";

interface DataResponse<Type> {
  data: Type[],
}

export function useURequest<Type>(request: string): [
  any,
  {
    loading: boolean;
    error?: Error;
    data: DataResponse<Type>;
  }
] {
  const { api } = useContext(OpenAPIContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [data, setData] = useState<DataResponse<Type>>({ data: [] });

  const operationMethod: any = useCallback(
    async (data: any) => {
      const requestParams = [
        undefined,
        data,
      ];

      setLoading(true);
      const client = await api.getClient();
      let res: AxiosResponse;
      try {
        res = await client[request](...requestParams);
        const resData: DataResponse<Type> = res.data;
        setData(resData);
        setError(undefined);
      } catch (err) {
        // @ts-ignore
        setError(err);
        setData({ data: [] });
      }
      setLoading(false);
      // @ts-ignore
      return res;
    },
    [setLoading, setData, setError]
  );

  return [operationMethod, { loading, error, data }];
}
