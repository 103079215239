import { useContext, useState, useCallback } from "react";
import { OpenAPIContext } from "./OpenAPIProvider";
import {
  AxiosResponse,
  AxiosError,
} from "openapi-client-axios";

interface DataResponse<Type> {
  data: Type,
}

export function useUSave<Type>(
  schema: string,
  fieldsToReturn: string[] = ['id'],
): [
    any,
    {
      loading: boolean;
      error?: Error;
      data: DataResponse<Type>;
    }
  ] {
  const { api } = useContext(OpenAPIContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [data, setData] = useState<any>(undefined);

  const operationMethod: any = useCallback(
    async (data: any, saveId?: string | number) => {
      const requestParams = [
        schema,
        {
          data,
          id: saveId ? saveId : "",
          fieldsToReturn
        },
      ];

      setLoading(true);
      const client = await api.getClient();
      let res: AxiosResponse;
      try {
        res = await client['NAEUSave'](...requestParams);
        const resData: DataResponse<Type> = res.data;
        setData(resData);
        setError(undefined);
      } catch (err) {
        // @ts-ignore
        setError(err);
        setData({ data: {} });
      }
      setLoading(false);
      // @ts-ignore
      return res;
    },
    [setLoading, setData, setError]
  );

  return [operationMethod, { loading, error, data }];
}
