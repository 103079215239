// import "./wdyr"

import * as React from 'react'

import { I18nextProvider } from 'react-i18next'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { OpenAPIContext, OpenAPIProvider } from './Components/OpenApi/OpenAPIProvider'
import { RecoilRoot } from 'recoil'
import { useOperation } from './Components/OpenApi/useOperation'
import { useOperationMethod } from './Components/OpenApi/useOperationMethod'
import { useUList } from './Components/OpenApi/useUList'
import AppRoutes from './Components/AppRoutes'
import { useULogin } from './Components/UseULogin';
import AppLoginRoutes from './Components/AppLoginRoutes'
import AppUserRoutes from './Components/AppUserRoutes';
import { useUSave } from './Components/OpenApi/useUSave';
import { useURemove } from './Components/OpenApi/useURemove'
import { useURequest } from './Components/OpenApi/useURequest';
import { naeUserState } from './state/NaeUserState'

interface Props {
  i18n?: any
  children: any
}

export const NaeAuthWrapper = ({ i18n, children }: Props) => {
  return (
    <OpenAPIProvider
      definition='/public/doc.json'
      axiosConfigDefaults={{
        baseURL: '/',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      }}
    >
      {!!i18n ? (
        <I18nextProvider i18n={i18n}>
          <RecoilRoot>{children}</RecoilRoot>
        </I18nextProvider>
      ) : (
        <RecoilRoot>{children}</RecoilRoot>
      )}

      <ToastContainer
        position={'bottom-right'}
        hideProgressBar
        newestOnTop={false}
      />
    </OpenAPIProvider>
  )
}

export const OpenApi = {
  OpenAPIContext: OpenAPIContext,
  useOperation: useOperation,
  useOperationMethod: useOperationMethod,
  useUList: useUList,
  useUSave: useUSave,
  useURemove: useURemove,
  useURequest: useURequest,

  naeUserState: naeUserState,

  toast: {
    success: (text: string) => {
      toast.success(text);
    },
    error: (text: string) => {
      toast.error(text);
    }
  },
}

export const Components = {
  AppRoutes: AppRoutes,
  AppLoginRoutes: AppLoginRoutes,
  AppUserRoutes: AppUserRoutes,
}

export const Hooks = {
  useULogin: useULogin
}