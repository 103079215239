import React, { useEffect, Fragment } from 'react'
import { useRecoilState } from 'recoil'
import { naeUserState } from '../state/NaeUserState'
import { useOperationMethod } from './OpenApi/useOperationMethod'
import { Redirect, useLocation } from 'react-router-dom'

interface Props {
  children: any
}

export default function UserSpaceWrapper(props: Props) {
  const location = useLocation();


  const [getUserData, getUserDataParams] = useOperationMethod('NAEauthGetProfile')
  const [userState, setUserState] = useRecoilState(naeUserState)

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    setUserState(getUserDataParams.data)
  }, [getUserDataParams.data])

  if (!!getUserDataParams.error) {
    // return <Fragment/>
    return <Redirect to={'/login'} />
  }

  if (!userState || !('id' in userState)) {
    return <Fragment />
  }

  if (getUserDataParams.loading) {
    return <Fragment />
  }

  if (location.pathname.match('sfs/builder')) {
    return <Fragment />
  }

  return <Fragment>{props.children}</Fragment>
}
